(function($) {
  var prodcat = prodcat || {};
  prodcat.ui = prodcat.ui || {};

  $(document).on('perlgem.cart.addItem', function(e, skuBaseId, payload) {
    payload = typeof payload !== 'undefined' ? payload : {};
    const args = {};

    // Set sku base id.
    args.skuBaseId = skuBaseId;

    // Set quantity.
    args.qty = payload.quantity ? payload.quantity : 1;

    // Set catBaseId.
    args.CAT_BASE_ID = payload.CAT_BASE_ID ? payload.CAT_BASE_ID : '';

    // Set replenishment if it exists.
    const frequency = payload.replenishment ? payload.replenishment : null;
    if (!!frequency) {
      args.REPLENISHMENT_FREQ = frequency;
    }
    prodcat.ui.addToCart(args);
  });
})(jQuery);
